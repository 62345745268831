import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fetchOverview } from '../../requests/index';
import { RouteComponentProps } from 'react-router-dom';
import redsquare from '../../assets/redsquare.png';
import chevron from '../../assets/chevron.svg';

interface RouteParams {
  id: string;
}
type IOverviewProps = RouteComponentProps<RouteParams>;

export function AwardOverview({ match, history }: IOverviewProps) {
  const [overview, setOverview] = useState<string>();
  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    getAndSetOverview();
  });

  async function getAndSetOverview() {
    const fetchedAward = await fetchOverview(match.params.id);
    setOverview(fetchedAward.overview);
    setIcon(fetchedAward.picture.url);
  }
  return (
    <StyledOverview>
      <img src={chevron} className='chevron' onClick={() => history.goBack()} />
      <div className='overview-container'>
        <img src={icon} />
        {overview && (
          <div
            className='overview-text'
            dangerouslySetInnerHTML={{ __html: overview }}
          />
        )}
      </div>
    </StyledOverview>
  );
}

const StyledOverview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 209px);

  .chevron {
    align-self: flex-start;
    transform: rotate(180deg);
    height: 20px;
    cursor: pointer;
    margin: 30px 0 0 30px;
  }

  .overview-container {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    @media screen and (max-width: 1020px) {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-top: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    img {
      width: 200px;
      align-self: flex-start;
      margin-left: 80px;
      margin-top: 60px;

      @media screen and (max-width: 1020px) {
        width: 175px;
      }

      @media screen and (max-width: 920px) {
        width: 150px;
        margin-right: 20px;
      }

      @media screen and (max-width: 600px) {
        width: 150px;
        margin: 40px 0 0 0;
        align-self: center;
      }
    }

    .overview-text {
      width: 600px;
      margin-top: 40px;

      @media screen and (max-width: 600px) {
        width: 90%;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 2.5rem;
        font-weight: 100;
        margin-bottom: 40px;
      }

      p {
        font-size: 1.4rem;
        line-height: 1.7;
        margin-top: 30px;
      }

      ul {
        list-style: square outside url(${redsquare});
        margin-left: 20px;
      }

      li {
        font-size: 1.4rem;
        line-height: 1.7;
        padding: 10px;
      }
    }
  }
`;
