import React from 'react';

import { AwardsList } from './AwardsList';
import { Banner } from './Banner';
import { FeaturedWinner } from './FeaturedWinner';
import { ImageGallery } from './ImageGallery';
import { ListAd } from './ListAd';
import { useAwardsState } from '../context/awardsContext';
import { LoaderBars, hooks } from '@engelvoelkersamericas/common-story-ui';
import styled from 'styled-components';
const ADMIN_ROLES = [
  'Head Office',
  'Operations',
  'Brokerage Manager',
  'License Partner',
  'Transaction Management',
];
const { useProfileState } = hooks;
export function AwardsHome() {
  const awardsState = useAwardsState();

  const homePage = awardsState.homePage;
  const { roles } = useProfileState();

  while (!homePage) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <LoaderBars />
      </div>
    );
  }

  const showAwardsOverviewLink = !!ADMIN_ROLES.find(
    (ar) => !!roles.find((r) => r.name === ar)
  );
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Banner />
      {/* {showAwardsOverviewLink ? (
        <a href='https://awards-overview.evipscloud.com/' target='_blank'>
          Management: Click here to access 2020 Team award allocations.
        </a>
      ) : null} */}
      <ImageGallery />
      <FeaturedWinner />
      {homePage?.adPositionOne && <ListAd ad={homePage.adPositionOne} />}
      <AwardsList />
      {homePage?.adPositionThree && <ListAd ad={homePage.adPositionThree} />}
    </div>
  );
}

const A = styled.a`
  color: #d20400;
  font-size: 1.8rem;
  line-height: 3.07;

  &:hover {
    text-decoration: underline;
  }
`;
