import React from 'react';
import styled from 'styled-components';
import { useAwardsState } from '../context/awardsContext';
import { Hero } from '@engelvoelkersamericas/common-story-ui';
export function Banner() {
  const awardsState = useAwardsState();
  const title = awardsState.homePage?.title;
  const heroImage = awardsState.homePage?.heroImage;
  const heroText = awardsState.homePage?.heroText;
  if (!heroImage) return null;
  return (
    <Hero imageUrl={heroImage.url} overlayStyles='width: 0 '>
      <StyledBanner>
        {heroText && (
          <div className='hero-section'>
            <p className='hero-title'>{title}</p>
            <div
              dangerouslySetInnerHTML={{ __html: heroText }}
              className='hero-text'
            />
          </div>
        )}
      </StyledBanner>
    </Hero>
  );
}

const StyledBanner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .hero-section {
    width: 100%;
    max-width: 1440px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 500px) {
      height: auto;
    }

    .hero-title {
      margin-top: 50px;
      font-size: 4.5rem;
      color: white;
      font-weight: 100;
    }

    .hero-text {
      width: 100%;
      display: flex;
      justify-content: center;

      p {
        max-width: 550px;
        color: white;
        text-align: center;
        font-size: 1.8rem;
        line-height: 1.78;
        padding-top: 20px;
        padding-bottom: 40px;
        font-weight: 300;
        a {
          color: #d20400;
          font-weight: bold;
        }
      }
    }
  }
`;
