import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAwardsState } from '../context/awardsContext';
import { fetchGalleries, fetchDefaultGalleries } from '../../requests/index';
import { BackArrow } from '../BackArrow';
import { GalleryModal } from './GalleryModal';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoaderBars } from '@engelvoelkersamericas/common-story-ui';

const SCROLL_OFFSET = -70;

interface RouteParams {
  id: string;
}
type IGalleryProps = RouteComponentProps<RouteParams>;

export function AwardGallery({ match }: IGalleryProps) {
  const awardsState = useAwardsState();
  const id = match.params.id;
  const year = awardsState.galleryYear;

  const [galleries, setGalleries] = useState([]);
  const [years, setYears] = useState([]);
  const [targetYear, setTargetYear] = useState<string>(year);
  const [awardTitle, setAwardTitle] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPhotos, setModalPhotos] = useState<any[]>([]);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getAndSetGalleries();
    initialScroll();
  }, []);

  async function getAndSetGalleries() {
    let fetchedGalleries = await fetchGalleries(id);
    const defaultGalleries = await fetchDefaultGalleries();
    if (fetchedGalleries.length) {
      setAwardTitle(fetchedGalleries[0].award.name);
      let galleryYears: any = [];
      fetchedGalleries.forEach((w: any) => {
        if (!galleryYears.includes(w.year)) {
          galleryYears.push(w.year);
        }
      });
      for (let i = 0; i < defaultGalleries.length; i++) {
        if (!galleryYears.includes(defaultGalleries[i].year)) {
          fetchedGalleries = [...fetchedGalleries, defaultGalleries[i]];
          galleryYears.push(defaultGalleries[i].year);
        }
      }
      galleryYears.sort((a: any, b: any) => {
        return b - a;
      });
      setYears(galleryYears);
      setGalleries(fetchedGalleries);
    } else {
      const galleryYears: any = [];
      defaultGalleries.forEach((w: any) => {
        if (!galleryYears.includes(w.year)) {
          galleryYears.push(w.year);
        }
      });
      galleryYears.sort((a: any, b: any) => {
        return b - a;
      });
      setYears(galleryYears);
      setGalleries(defaultGalleries);
    }
    setLoading(false);
  }

  function openModal(photos: any[], index: number) {
    setModalIndex(index);
    setModalOpen(true);
    setModalPhotos(photos);
  }

  function updateYear(val: string) {
    setTargetYear(val);
    const pos = document.getElementById(val)?.offsetTop;
    if (typeof pos === 'number') {
      window.scrollTo({ top: pos + SCROLL_OFFSET, behavior: 'smooth' });
    }
  }

  function initialScroll() {
    if (!document.getElementById(targetYear)) {
      window.requestAnimationFrame(initialScroll);
    } else {
      const pos = document.getElementById(targetYear)?.offsetTop;
      if (typeof pos === 'number') {
        window.scrollTo({ top: pos + SCROLL_OFFSET, behavior: 'smooth' });
      }
    }
  }

  if (loading) {
    return (
      <LoaderContainer>
        <LoaderBars />
      </LoaderContainer>
    );
  }

  return (
    <>
      {!galleries.length ? (
        <h1>No galleries yet!</h1>
      ) : (
        <StyledGalleries>
          <div className='home-arrow'>
            <Link to='/'>
              <BackArrow />
            </Link>
            <p>Home</p>
          </div>
          <div className='award-galleries'>
            <h2>{awardTitle} Award Winners</h2>
            <div className='years-container'>
              {years.map((y) => (
                <p
                  className={y === targetYear ? 'target-year year' : 'year'}
                  onClick={() => updateYear(y)}
                >
                  {y}
                </p>
              ))}
            </div>
            {years.map((y) => (
              <div className='gallery-container' id={y}>
                <div className='gallery-labels'>
                  <div className='back-arrow'>
                    <Link to={{ pathname: `/winners/${id}` }}>
                      <BackArrow />
                    </Link>
                    <p>Back to Winners</p>
                  </div>
                  <h3>{y}</h3>
                  <h5 />
                </div>
                {galleries.map((g: any) =>
                  g.year === y ? (
                    <div className='images-container'>
                      {g.photos.map((p: any, i: number) => (
                        <div
                          className='image-container'
                          onClick={() => openModal(g.photos, i)}
                        >
                          <img src={p.url} />
                        </div>
                      ))}
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
          {modalOpen ? (
            <GalleryModal
              photos={modalPhotos}
              setModalOpen={setModalOpen}
              initial={modalIndex}
            />
          ) : null}
        </StyledGalleries>
      )}
    </>
  );
}

const LoaderContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 210px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledGalleries = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-arrow {
    margin: 30px auto 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;

    @media screen and (max-width: 620px) {
      height: 16px;
    }

    p {
      margin-left: 10px;
      font-size: 2rem;

      @media screen and (max-width: 620px) {
        font-size: 1.6rem;
      }
    }
  }

  .award-galleries {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1200px;

    @media screen and (max-width: 1300px) {
      width: 1000px;
    }

    @media screen and (max-width: 1050px) {
      width: 800px;
    }

    @media screen and (max-width: 850px) {
      width: 600px;
    }

    @media screen and (max-width: 620px) {
      width: 100%;
    }

    h2 {
      align-self: center;
      font-size: 2.8rem;
      font-weight: 100;

      @media screen and (max-width: 620px) {
        text-align: center;
        font-size: 2.5rem;
      }
    }

    .years-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-self: center;

      .year {
        margin: 0 20px;
        font-size: 1.4rem;
        cursor: pointer;
      }

      .target-year {
        color: red;
      }
    }

    .gallery-container {
      width: 100%;
      margin-top: 50px;

      .gallery-labels {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;

        @media screen and (max-width: 620px) {
          height: 60px;
          margin-top: 20px;
        }

        .back-arrow {
          width: 20%;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 20px;

          @media screen and (max-width: 620px) {
            width: auto;
            margin-left: 10px;
          }

          p {
            margin-left: 10px;
            font-size: 1.4rem;
          }
        }

        h3 {
          font-size: 2.8rem;
          font-weight: 100;

          @media screen and (max-width: 620px) {
            margin-right: 10px;
          }
        }

        h5 {
          visibility: hidden;
          width: 20%;

          @media screen and (max-width: 620px) {
            display: none;
          }
        }
      }

      .images-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 277.5px);
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        list-style-type: none;
        max-width: 100%;
        margin: 0 auto;
        padding: 0;

        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(auto-fill, 227.5px);
        }

        @media screen and (max-width: 1050px) {
          grid-template-columns: repeat(auto-fill, 246.6px);
        }

        @media screen and (max-width: 850px) {
          grid-template-columns: repeat(auto-fill, 285px);
        }

        @media screen and (max-width: 620px) {
          display: flex;
          flex-direction: column;
          margin-top: 0;
        }

        .image-container {
          display: flex;
          justify-content: center;
          width: 277.5px;
          height: 277.5px;
          overflow: hidden;
          margin: 0;

          @media screen and (max-width: 1300px) {
            width: 227.5px;
            height: 227.5px;
          }

          @media screen and (max-width: 1050px) {
            width: 246.6px;
            height: 246.6px;
          }

          @media screen and (max-width: 850px) {
            width: 285px;
            height: 285px;
          }

          @media screen and (max-width: 620px) {
            width: 90%;
            margin-left: 5%;
          }

          img {
            height: 250px;
            align-self: center;
            margin: 0;
            cursor: pointer;

            @media screen and (max-width: 1300px) {
              height: 200px;
            }

            @media screen and (max-width: 850px) {
              height: 225px;
            }
          }
        }
      }
    }
  }
`;
