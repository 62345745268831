import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAwardsState, useAwardsDispatch } from '../context/awardsContext';
import { fetchWinners } from '../../requests/index';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BackArrow } from '../BackArrow';
import photogallery from '../../assets/photogallery.svg';
import { SVG, hooks } from '@engelvoelkersamericas/common-story-ui';
const { LeftArrow } = SVG;

interface IWinner {
  id: string;
  year: string;
  columnOne: string;
  columnTwo: string;
  columnThree: string;
}

const SCROLL_OFFSET = -70;

interface RouteParams {
  id: string;
}
type IWinnersProps = RouteComponentProps<RouteParams>;

const { useGlobalDispatch, useGlobalState } = hooks;

export function AwardWinners({ match }: IWinnersProps) {
  const dispatch = useAwardsDispatch();
  const id = match.params.id;
  const globalDispatch = useGlobalDispatch();
  const { isGlobalLoading } = useGlobalState();

  const [winners, setWinners] = useState<IWinner[]>([]);
  const [years, setYears] = useState([]);
  const [awardName, setAwardName] = useState<string>();
  const [columns, setColumns] = useState<string[]>([]);
  const [targetYear, setTargetYear] = useState<string>();

  useEffect(() => {
    getAndSetWinners();
  }, []);

  async function getAndSetWinners() {
    globalDispatch({ type: 'set global loading', payload: true });
    let fetchedWinners = await fetchWinners(id);
    if (fetchedWinners.length) {
      setAwardName(fetchedWinners[0].award.name);
      setColumns([
        fetchedWinners[0].award.winnerColumnOne,
        fetchedWinners[0].award.winnerColumnTwo,
        fetchedWinners[0].award.winnerColumnThree,
      ]);
      let arr = fetchedWinners.slice();
      console.log(arr);
      let sortedWinners;

      if (!fetchedWinners[0].columnOne) {
        sortedWinners = arr.sort((a: any, b: any) => {
          return b.year - a.year;
        });
      } else if (fetchedWinners[0].columnOne.split(' ')[0] === 'Engel') {
        sortedWinners = arr.sort((a: any, b: any) => {
          if (!a.columnOne || !b.columnOne) {
            return 0;
          }
          return a.columnOne.toUpperCase() < b.columnOne.toUpperCase()
            ? -1
            : a.columnOne.toUpperCase() > b.columnOne.toUpperCase()
            ? 1
            : 0;
        });
      } else {
        sortedWinners = arr.sort((a: any, b: any) => {
          if (!a.columnOne || !b.columnOne) {
            return 0;
          }
          return (
            Number(a.columnOne.match(/(\d+)/g)) -
            Number(b.columnOne.match(/(\d+)/g))
          );
        });
      }

      setWinners(sortedWinners);

      const winnerYears: any = [];
      fetchedWinners.forEach((w: any) => {
        if (!winnerYears.includes(w.year)) {
          winnerYears.push(w.year);
        }
      });
      winnerYears.sort((a: any, b: any) => {
        return b - a;
      });
      setYears(winnerYears);
      setTargetYear(winnerYears[0]);
    }
    globalDispatch({ type: 'set global loading', payload: false });
  }

  function updateYear(val: string) {
    setTargetYear(val);
    const pos = document.getElementById(val)?.offsetTop;
    if (typeof pos === 'number') {
      window.scrollTo({ top: pos + SCROLL_OFFSET, behavior: 'smooth' });
    }
  }

  function scrollToTop() {
    setTargetYear(years[0]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  if (!winners.length && !isGlobalLoading) {
    return <h1>No winners yet!</h1>;
  }

  function renderWinners() {
    if (id !== '1' && id !== '14' && id !== '15') {
      if (
        id === '7' ||
        id === '8' ||
        id === '3' ||
        id === '4' ||
        id === '5' ||
        id === '6' ||
        id === '2' ||
        id === '13'
      ) {
        return (
          <div>
            {years && winners
              ? years.map((y, i) => (
                  <StyledList>
                    <div className='list-header' id={y}>
                      <Link
                        to={{ pathname: `/winners/${id}/galleries` }}
                        onClick={() =>
                          dispatch({ type: 'set gallery year', payload: y })
                        }
                      >
                        <div className='gallery-link'>
                          <img src={photogallery} />
                          <p>Photo Gallery</p>
                        </div>
                      </Link>
                      <h2>{y}</h2>
                      <div
                        className={i === 0 ? 'up-arrow hidden' : 'up-arrow'}
                        onClick={scrollToTop}
                      >
                        <LeftArrow />
                      </div>
                    </div>
                    <table className='two-column-table'>
                      <tr>
                        <th>{columns[0]}</th>
                        <th>{columns[1]}</th>
                        {/* <th>{columns[2]}</th> */}
                      </tr>

                      {winners.map(
                        (w) =>
                          w.year === y && (
                            <tr>
                              <td className='column-one'>{w.columnOne}</td>
                              <td className='column-two'>{w.columnTwo}</td>
                              {/* <td className='column-three'>{w.columnThree}</td> */}
                            </tr>
                          )
                      )}
                    </table>
                  </StyledList>
                ))
              : null}
          </div>
        );
      } else {
        return (
          <div>
            {years && winners
              ? years.map((y, i) => (
                  <StyledList>
                    <div className='list-header' id={y}>
                      <Link
                        to={{ pathname: `/winners/${id}/galleries` }}
                        onClick={() =>
                          dispatch({ type: 'set gallery year', payload: y })
                        }
                      >
                        <div className='gallery-link'>
                          <img src={photogallery} />
                          <p>Photo Gallery</p>
                        </div>
                      </Link>
                      <h2
                        style={{
                          visibility: id === '1' ? 'hidden' : 'visible',
                        }}
                      >
                        {y}
                      </h2>
                      <div
                        className={i === 0 ? 'up-arrow hidden' : 'up-arrow'}
                        onClick={scrollToTop}
                      >
                        <LeftArrow />
                      </div>
                    </div>
                    <table className='three-column-table'>
                      <tr>
                        <th>{columns[0]}</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                      </tr>

                      {winners.map(
                        (w) =>
                          w.year === y && (
                            <tr>
                              <td className='column-one'>{w.columnOne}</td>
                              <td className='column-two'>{w.columnTwo}</td>
                              <td className='column-three'>{w.columnThree}</td>
                            </tr>
                          )
                      )}
                    </table>
                  </StyledList>
                ))
              : null}
          </div>
        );
      }
    } else {
      return (
        <div>
          {years && winners
            ? years.map(
                (y, i) =>
                  i === 0 && (
                    <StyledList>
                      <div className='list-header' id={y}>
                        <Link
                          to={{ pathname: `/winners/${id}/galleries` }}
                          onClick={() =>
                            dispatch({ type: 'set gallery year', payload: y })
                          }
                        >
                          <div className='gallery-link'>
                            <img src={photogallery} />
                            <p>Photo Gallery</p>
                          </div>
                        </Link>
                        {/* <h2 style={{ visibility: id === '1' ? 'hidden' : 'visible' }}>
                    {y}
                  </h2> */}
                        <div
                          className={i === 0 ? 'up-arrow hidden' : 'up-arrow'}
                          onClick={scrollToTop}
                        >
                          <LeftArrow />
                        </div>
                      </div>
                      <table className='three-column-table'>
                        <tr>
                          <th>{columns[0]}</th>
                          <th>{columns[1]}</th>
                          {id !== '1' && <th>{columns[2]}</th>}
                        </tr>

                        {winners.map((w) => (
                          <tr>
                            <td className='column-one'>{w.year}</td>
                            <td className='column-two'>{w.columnTwo}</td>
                            {id !== '1' && (
                              <td className='column-three'>{w.columnThree}</td>
                            )}
                          </tr>
                        ))}
                      </table>
                    </StyledList>
                  )
              )
            : null}
        </div>
      );
    }
  }

  return (
    <Container>
      <div className='home-arrow'>
        <Link to='/'>
          <BackArrow />
        </Link>
        <p>Home</p>
      </div>
      <h1>{awardName} Award Winners</h1>
      {id !== '1' && (
        <div className='years-container'>
          {years.map((y) => (
            <p
              className={y === targetYear ? 'target-year year' : 'year'}
              onClick={() => updateYear(y)}
            >
              {y}
            </p>
          ))}
        </div>
      )}
      {renderWinners()}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-arrow {
    margin: 30px auto 30px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;

    @media screen and (max-width: 500px) {
      height: 16px;
    }

    p {
      margin-left: 10px;
      font-size: 2rem;

      @media screen and (max-width: 500px) {
        font-size: 1.6rem;
      }
    }
  }

  h1 {
    font-size: 2.8rem;
    font-weight: 100;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .years-container {
    display: flex;
    justify-content: center;

    .year {
      margin: 20px;
      font-size: 1.4rem;
      cursor: pointer;
    }

    .target-year {
      color: red;
    }
  }
`;

const StyledList = styled.div`
  width: 1200px;
  margin-top: 60px;

  @media screen and (max-width: 1230px) {
    width: 1000px;
  }

  @media screen and (max-width: 1050px) {
    width: 90%;
    margin-left: 5%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }

  .list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media screen and (max-width: 500px) {
      height: 100px;
      align-items: flex-end;
    }

    .gallery-link {
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 500px) {
        margin-left: 10px;
      }

      img {
        height: 20px;
        width: auto;
        margin-right: 15px;
      }

      p {
        font-size: 1.4rem;
      }
    }

    h2 {
      font-size: 2.8rem;
      font-weight: 100;

      @media screen and (max-width: 500px) {
        align-self: flex-start;
      }
    }

    .up-arrow {
      text-align: right;
      cursor: pointer;
      margin-right: 20px;
      margin-left: 80px;

      @media screen and (max-width: 500px) {
        margin-bottom: 15px;
        margin-left: 90px;
      }
      svg {
        transform: rotate(90deg);
        height: 14px;
        width: 14px;
        opacity: 0.6;
      }
    }

    .hidden {
      visibility: hidden;
    }
  }

  .three-column-table {
    width: 100%;
    border-spacing: 0;
    border-bottom: 1px solid #c5c5c5;
    margin-bottom: 40px;
    tr {
      margin: 0;
      th {
        text-align: left;
        padding: 10px;
        font-size: 1.4rem;
        color: black;
      }

      td {
        padding: 8px;
        border-top: 1px solid #c5c5c5;
        border-right: 1px solid #c5c5c5;
        border-collapse: collapse;
        font-size: 1.4rem;
      }

      .column-one {
        width: 115px;
      }

      .column-two {
        width: 320px;
      }

      .column-three {
        width: 365px;
        border-right: none;
      }
    }
  }

  .two-column-table {
    width: 100%;
    border-spacing: 0;
    border-bottom: 1px solid #c5c5c5;
    margin-bottom: 40px;
    tr {
      margin: 0;
      th {
        text-align: left;
        padding: 10px;
        font-size: 1.4rem;
        color: black;
      }

      td {
        padding: 8px;
        border-top: 1px solid #c5c5c5;
        border-right: 1px solid #c5c5c5;
        border-collapse: collapse;
        font-size: 1.4rem;
      }

      .column-one {
        width: 200px;
      }

      .column-two {
        width: 600px;
        border-right: none;
      }
    }
  }
`;
