import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

let queryUrl: string;

if (window.location.hostname === 'localhost') {
  queryUrl = 'https://cms.evipscloud.com'; //'http://localhost:1337';
} else {
  queryUrl = 'https://cms.evipscloud.com';
}

const client = new ApolloClient({
  uri: `${queryUrl}/graphql`,
  cache: new InMemoryCache(),
});

export async function fetchHomePage(): Promise<any> {
  try {
    return client
      .query({
        query: gql`
          {
            homePage {
              title
              heroText
              heroImage {
                id
                url
              }
              gallery {
                id
                url
              }
              adPositionOne {
                Label
                Link
                Image {
                  url
                }
              }
              adPositionTwo {
                Label
                Link
                Image {
                  url
                }
              }
              adPositionThree {
                Label
                Link
                Image {
                  url
                }
              }
            }
            awards(sort: "order:asc") {
              id
              order
              award_category {
                id
                name
              }
              name
              picture {
                id
                url
              }
              link
            }
            awardHighlights {
              id
              title
              quote
              awardWinner
              shop
              year
              award {
                id
                name
                picture {
                  id
                  url
                }
              }
              picture {
                id
                url
              }
            }
            awardCategories(sort: "orderPosition:asc") {
              id
              name
              orderPosition
            }
          }
        `,
      })
      .then((res) => {
        return res.data;
      });
  } catch (error) {
    throw error;
  }
}

export async function fetchOverview(id: string): Promise<any> {
  try {
    return client
      .query({
        query: gql`
      {
        awards (where: {id: ${id}}) {
          id
          name
          overview
          picture {
            url
          }
        }
      }
    `,
      })
      .then((res) => {
        return res.data.awards[0];
      });
  } catch (error) {
    throw error;
  }
}

const BATCH_SIZE = 3000; // based on API limit

export async function fetchWinners(id: string): Promise<any> {
  if (!id) {
    return [];
  } else {
    try {
      let allWinners: any[] = [];
      let start = 0;
      let hasMore = true;

      while (hasMore) {
        const { data } = await client.query({
          query: gql`
            {
              awardWinners(
                where: { award: { id: ${id} } }
                sort: "columnOne"
                start: ${start}
                limit: ${BATCH_SIZE}
              ) {
                id
                year
                columnOne
                columnTwo
                columnThree
                award {
                  id
                  name
                  winnerColumnOne
                  winnerColumnTwo
                  winnerColumnThree
                }
              }
            }
          `,
        });

        allWinners = [...allWinners, ...data.awardWinners];

        if (data.awardWinners.length < BATCH_SIZE) {
          hasMore = false;
        } else {
          start += BATCH_SIZE;
        }
      }

      return allWinners;
    } catch (error) {
      throw error;
    }
  }
}




export async function fetchGalleries(id: string): Promise<any> {
  if (!id) {
    return [];
  } else {
    try {
      return client
        .query({
          query: gql`
      {
        awardGalleries (where: {award: {id: ${id}}}) {
          id
          year
          award {
            id
            name
          }
          photos {
            id
            url
          }
        }
      }
    `,
        })
        .then((res) => {
          return res.data.awardGalleries;
        });
    } catch (error) {
      throw error;
    }
  }
}

export async function fetchDefaultGalleries(): Promise<any> {
  try {
    return client
      .query({
        query: gql`
          {
            awardGalleries(where: { isDefault: true }) {
              id
              year
              award {
                id
                name
              }
              photos {
                id
                url
              }
            }
          }
        `,
      })
      .then((res) => {
        return res.data.awardGalleries;
      });
  } catch (error) {
    throw error;
  }
}

// query to get top-level schema
// {
//   __schema {
//     queryType {
//       fields {
//         name
//       }
//     }
//   }
// }

// mutation to create award winner
// mutation AddWinner($award: ID!, $year: Int!, $columnOne: String!, $columnTwo: String!,
// $columnThree: String!) {
//   createAwardWinner(input: {data: {award: $award, year: $year, columnOne: $columnOne,
// columnTwo: $columnTwo, columnThree: $columnThree}}) {
//       awardWinner {
//           id
//       }
//   }
// }
