import React, { RefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import chevron from '../../assets/chevron.svg';
import download from '../../assets/download.svg';
import close from '../../assets/close.svg';
import { SVG } from '@engelvoelkersamericas/common-story-ui';
import { IPhoto } from '../context/awardsContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

interface IGalleryModalProps {
  photos?: IPhoto[];
  setModalOpen: (val: boolean) => void;
  initial: number;
}

export function GalleryModal(props: IGalleryModalProps) {
  const { Cross } = SVG;
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <img className='arrow arrow-right' src={chevron} />,
    prevArrow: <img className='arrow arrow-left' src={chevron} />,
    initialSlide: props.initial,
  };

  const ref = useRef<HTMLDivElement>(null);

  const useOutsideClick = (
    ref: RefObject<HTMLDivElement>,
    callback: () => void
  ) => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  useOutsideClick(ref, () => {
    props.setModalOpen(false);
  });

  return (
    <StyledModal>
      <div className='modal-slideshow' ref={ref}>
        <Slider {...settings}>
          {props.photos &&
            props.photos.map((p) => (
              <div className='image-container'>
                <div className='image-sub-container'>
                  <div className='button-container'>
                    <a href={p.url} download target='_blank'>
                      <img src={download} />
                    </a>
                    <img
                      src={close}
                      onClick={() => props.setModalOpen(false)}
                    />
                  </div>
                  <img className='display-image' src={p.url} />
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </StyledModal>
  );
}

const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;

  .modal-slideshow {
    width: 1220px;
    max-width: 90vw;
    background: none;
    margin-top: 50px;

    .image-container {
      display: flex !important;
      justify-content: center;
      img {
        outline: none;
      }
      .image-sub-container {
        position: relative;
        display: inline-block;
      }
      .button-container {
        position: absolute;
        top: 2%;
        right: 11%;
        display: flex;
        background: rgba(0, 0, 0, 0.5);
        padding: 5px;
        border-radius: 3px;
        width: 60px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          filter: invert(1);
          cursor: pointer;
          outline: none;
        }
      }
    }
    .display-image {
      max-height: 80vh;
      height: auto;
      max-width: 80%;
      width: auto;
      margin: 0 auto;
    }
    .slick-arrow {
      height: 20px;
      width: 20px;
      filter: invert(1);
    }

    .slick-prev {
      transform: rotate(180deg) translateY(10px);
      left: 0px;
      z-index: 900;
    }

    .slick-next {
      right: 0px;
    }
  }
`;
