import React, { useState } from 'react';
import styled from 'styled-components';
import {
  useAwardsState,
  useAwardsDispatch,
  IHomeAward,
} from '../context/awardsContext';
import { Link } from 'react-router-dom';
import { ListAd } from './ListAd';

export function AwardsList() {
  const awardsState = useAwardsState();
  const awards = awardsState.homeAwards;
  const homePage = awardsState.homePage;
  const categories = awardsState.categories;
  const dispatch = useAwardsDispatch();

  const [targetCategory, setTargetCategory] = useState<string>('All');
  const [filterAwards, setFilterAwards] = useState<IHomeAward[]>([]);

  function setActiveAward(id: string) {
    dispatch({ type: 'set active award', payload: id });
  }

  function updateCategory(newCategory: string) {
    setTargetCategory(newCategory);
    let renderAwards: IHomeAward[] = [];
    if (awards) {
      for (let i = 0; i < awards.length; i++) {
        if (
          awards[i].award_category !== null &&
          awards[i].award_category.name === newCategory
        ) {
          renderAwards.push(awards[i]);
        }
      }
    }
    setFilterAwards(renderAwards);
  }

  return (
    <StyledAwards>
      <h2>Awards</h2>
      <div className='category-container'>
        <p
          className={targetCategory === 'All' ? 'active-category' : ''}
          onClick={() => setTargetCategory('All')}
        >
          All
        </p>
        {categories.map((c) => (
          <p
            className={targetCategory === c.name ? 'active-category' : ''}
            onClick={() => updateCategory(c.name)}
          >
            {c.name}
          </p>
        ))}
      </div>
      {targetCategory === 'All' && (
        <div className='awards-container'>
          {awards &&
            awards.map((a, i) =>
              a.link ? (
                homePage?.adPositionTwo && i === 12 ? (
                  <>
                    <ListAd ad={homePage.adPositionTwo} />
                    <a href={a.link} target='_blank'>
                      <div className='award-card'>
                        <div className='card-image'>
                          <img src={a.picture.url} />
                        </div>
                        <h4>{a.name}</h4>
                        {!a.link && (
                          <div className='link-container'>
                            <Link to={{ pathname: `/overview/${a.id}` }}>
                              Overview
                            </Link>
                            |
                            <Link
                              to={{ pathname: `/winners/${a.id}` }}
                              onClick={() => setActiveAward(a.id)}
                            >
                              Winners
                            </Link>
                          </div>
                        )}
                      </div>
                    </a>
                  </>
                ) : (
                  <a href={a.link} target='_blank'>
                    <div className='award-card'>
                      <div className='card-image'>
                        <img src={a.picture.url} />
                      </div>
                      <h4>{a.name}</h4>
                      {!a.link && (
                        <div className='link-container'>
                          <Link to={{ pathname: `/overview/${a.id}` }}>
                            Overview
                          </Link>
                          |
                          <Link
                            to={{ pathname: `/winners/${a.id}` }}
                            onClick={() => setActiveAward(a.id)}
                          >
                            Winners
                          </Link>
                        </div>
                      )}
                    </div>
                  </a>
                )
              ) : homePage?.adPositionTwo && i === 12 ? (
                <>
                  <ListAd ad={homePage.adPositionTwo} />
                  <div className='award-card'>
                    <div className='card-image'>
                      <img src={a.picture.url} />
                    </div>
                    <h4>{a.name}</h4>
                    {!a.link && (
                      <div className='link-container'>
                        <Link to={{ pathname: `/overview/${a.id}` }}>
                          Overview
                        </Link>
                        |
                        <Link
                          to={{ pathname: `/winners/${a.id}` }}
                          onClick={() => setActiveAward(a.id)}
                        >
                          Winners
                        </Link>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='award-card'>
                  <div className='card-image'>
                    <img src={a.picture.url} />
                  </div>
                  <h4>{a.name}</h4>
                  {!a.link && (
                    <div className='link-container'>
                      <Link to={{ pathname: `/overview/${a.id}` }}>
                        Overview
                      </Link>
                      |
                      <Link
                        to={{ pathname: `/winners/${a.id}` }}
                        onClick={() => setActiveAward(a.id)}
                      >
                        Winners
                      </Link>
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      )}
      {targetCategory !== 'All' && (
        <div className='awards-container'>
          {filterAwards &&
            filterAwards.map((a, i) =>
              a.link ? (
                homePage?.adPositionTwo && i === 12 ? (
                  <>
                    <ListAd ad={homePage.adPositionTwo} />
                    <a href={a.link} target='_blank'>
                      <div className='award-card'>
                        <div className='card-image'>
                          <img src={a.picture.url} />
                        </div>
                        <h4>{a.name}</h4>
                        {!a.link && (
                          <div className='link-container'>
                            <Link to={{ pathname: `/overview/${a.id}` }}>
                              Overview
                            </Link>
                            |
                            <Link
                              to={{ pathname: `/winners/${a.id}` }}
                              onClick={() => setActiveAward(a.id)}
                            >
                              Winners
                            </Link>
                          </div>
                        )}
                      </div>
                    </a>
                  </>
                ) : (
                  <a href={a.link} target='_blank'>
                    <div className='award-card'>
                      <div className='card-image'>
                        <img src={a.picture.url} />
                      </div>
                      <h4>{a.name}</h4>
                      {!a.link && (
                        <div className='link-container'>
                          <Link to={{ pathname: `/overview/${a.id}` }}>
                            Overview
                          </Link>
                          |
                          <Link
                            to={{ pathname: `/winners/${a.id}` }}
                            onClick={() => setActiveAward(a.id)}
                          >
                            Winners
                          </Link>
                        </div>
                      )}
                    </div>
                  </a>
                )
              ) : homePage?.adPositionTwo && i === 12 ? (
                <>
                  <ListAd ad={homePage.adPositionTwo} />
                  <div className='award-card'>
                    <div className='card-image'>
                      <img src={a.picture.url} />
                    </div>
                    <h4>{a.name}</h4>
                    {!a.link && (
                      <div className='link-container'>
                        <Link to={{ pathname: `/overview/${a.id}` }}>
                          Overview
                        </Link>
                        |
                        <Link
                          to={{ pathname: `/winners/${a.id}` }}
                          onClick={() => setActiveAward(a.id)}
                        >
                          Winners
                        </Link>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='award-card'>
                  <div className='card-image'>
                    <img src={a.picture.url} />
                  </div>
                  <h4>{a.name}</h4>
                  {!a.link && (
                    <div className='link-container'>
                      <Link to={{ pathname: `/overview/${a.id}` }}>
                        Overview
                      </Link>
                      |
                      <Link
                        to={{ pathname: `/winners/${a.id}` }}
                        onClick={() => setActiveAward(a.id)}
                      >
                        Winners
                      </Link>
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      )}
    </StyledAwards>
  );
}

const StyledAwards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 500px) {
    margin-top: 40px;
  }

  h2 {
    font-size: 3rem;
    font-weight: 100;
  }

  .category-container {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    width: 950px;

    @media screen and (max-width: 980px) {
      justify-content: flex-start;
      align-self: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 90%;
      margin-left: 5%;
    }

    p {
      margin: 20px 30px;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: center;
      white-space: nowrap;

      @media screen and (max-width: 500px) {
        flex: 0 0 auto;
      }
    }

    .active-category {
      color: red;
    }
  }

  .category-container::-webkit-scrollbar {
    height: 5px;
    width: auto;
    border-radius: 3px;
  }

  .category-container::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 3px;
  }

  .category-container::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 3px;
  }

  .awards-container {
    width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1220px) {
      width: 1000px;
    }

    @media screen and (max-width: 1020px) {
      width: 800px;
    }

    @media screen and (max-width: 850px) {
      width: 750px;
    }

    @media screen and (max-width: 770px) {
      width: 600px;
    }

    @media screen and (max-width: 620px) {
      width: 480px;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }

    .award-card {
      width: 300px;
      text-align: center;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 1220px) {
        width: 250px;
      }

      @media screen and (max-width: 1020px) {
        width: 200px;
      }

      @media screen and (max-width: 850px) {
        width: 250px;
      }

      @media screen and (max-width: 770px) {
        width: 300px;
      }

      @media screen and (max-width: 620px) {
        width: 240px;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 40px;
      }

      .card-image {
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          max-width: 200px;
          max-height: 200px;
          align-self: center;
        }
      }

      h4 {
        font-size: 1.4rem;
        max-width: 90%;
      }

      .link-container {
        font-size: 1.4rem;

        a {
          margin: 10px;
          font-size: 1.4rem;
        }
      }
    }
  }
`;
