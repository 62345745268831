import React from 'react';
import styled from 'styled-components';
import { useAwardsState } from '../context/awardsContext';

import highlights from '../../assets/highlights.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

export function FeaturedWinner() {
  const awardsState = useAwardsState();
  const awardHighlights = awardsState.awardHighlights;

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    dots: true,
  };

  return (
    <Container>
      {awardHighlights.length > 0 && (
        <Slider {...settings}>
          {awardHighlights.map((highlight) => (
            <StyledHighlights>
              <>
                <p className='highlight-title'>{highlight.title}</p>
                <div className='featured-container'>
                  <div className='highlight-container'>
                    <div className='highlight-flex'>
                      <img
                        src={highlight.picture.url}
                        className='highlight-image'
                      />
                      <div className='highlight-text'>
                        <p className='quote'>
                          <span style={{ color: '#d9252a', padding: '5px' }}>
                            "
                          </span>
                          {highlight.quote}
                          <span style={{ color: '#d9252a', padding: '5px' }}>
                            "
                          </span>
                        </p>
                        <p className='subtext'>{highlight.awardWinner}</p>
                        <p className='subtext'>{highlight.shop}</p>
                        <p className='subtext'>
                          <strong>
                            {highlight.year} {highlight.award.name} Winner
                          </strong>
                        </p>
                      </div>
                      <img
                        src={highlight.award.picture.url}
                        className='award-icon'
                      />
                    </div>
                  </div>
                </div>
              </>
            </StyledHighlights>
          ))}
        </Slider>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;

  .slick-slider {
    width: 100%;
    max-width: 1440px;
  }
`;

const StyledHighlights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  .highlight-title {
    text-align: center;
    font-size: 3rem;
    font-weight: 100;
    margin-bottom: 80px;

    @media screen and (max-width: 500px) {
      margin-bottom: 30px;
    }
  }
  .featured-container {
    width: 100%;
    max-width: 1440px;
    height: 300px;
    background-image: url(${highlights});
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 500px) {
      height: auto;
    }

    .highlight-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .highlight-flex {
        display: flex;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: center;
        }

        .highlight-image {
          width: 100px;
          height: auto;
          align-self: center;
          margin-right: 30px;
          align-self: flex-start;

          @media screen and (max-width: 580px) {
            width: 75px;
          }

          @media screen and (max-width: 500px) {
            width: 100px;
            align-self: center;
            margin-bottom: 20px;
          }
        }

        .highlight-text {
          display: flex;
          flex-direction: column;
          width: 400px;
          align-self: flex-start;
          margin-top: -20px;

          @media screen and (max-width: 680px) {
            width: 300px;
          }

          @media screen and (max-width: 500px) {
            width: 90%;
            margin-left: 5%;
          }

          .quote {
            font-size: 1.8rem;
            line-height: 1.8;
          }

          .subtext {
            font-size: 1.2rem;
            margin: 2px;
          }
        }

        .award-icon {
          max-width: 250px;
          /* height: auto; */
          max-height: 300px;
          align-self: center;
          margin-left: 0px;
          margin-top: -50px;

          @media screen and (max-width: 850px) {
            width: 200px;
          }

          @media screen and (max-width: 770px) {
            width: 150px;
            margin-left: -20px;
          }

          @media screen and (max-width: 580px) {
            width: 100px;
            margin-left: -20px;
          }

          @media screen and (max-width: 500px) {
            width: 150px;
            margin: 20px 0 20px 0;
          }
        }
      }
    }
  }
`;
