import React from 'react';
import styled from 'styled-components';
import { IAwardAd } from '../context/awardsContext';

interface IAdProps {
  ad: IAwardAd;
}

export function ListAd({ ad }: IAdProps) {
  return (
    <Container>
      <a href={ad.Link} target='_blank' rel='noopener'>
        <img src={ad.Image.url} alt={ad.Label} />
      </a>
    </Container>
  );
}

const Container = styled.div`
  width: 90vw;
  height: auto;
  margin: 40px 0 60px 0;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 1050px;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    cursor: pointer;
  }
`;
