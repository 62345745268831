import React from 'react';
import {
  hooks,
  Header,
  Footer,
} from '@engelvoelkersamericas/common-story-ui';
import Router from './Router';
import { AwardsProvider } from './components/context/awardsContext';
import darkIcon from './assets/darkAwards.svg';

const { GlobalProvider, ProfileProvider } = hooks;

// example graphql client
// const client = new ApolloClient({
//   uri: 'http://44.241.57.178/graphql',
//   cache: new InMemoryCache(),
// });

function App() {
  return (
    <GlobalProvider>
      <ProfileProvider>
        <AwardsProvider>
          <Header
            cmsCourseId={15}
            productName='Awards'
            productLogo='/global/static/icons/ev/awards.svg'
            darkModeLogo={darkIcon}
            showAppMenu
            showSearch={false}
            faqFolders={[{ label: 'Popular', faqName: 'awardsPopular' }]}
          />
          <Router />
          <Footer children={<></>}/>
        </AwardsProvider>
      </ProfileProvider>
    </GlobalProvider>
  );
}

export default App;
