import React from 'react';
import styled from 'styled-components';
import { SVG } from '@engelvoelkersamericas/common-story-ui';
const { LeftArrow } = SVG;
export function BackArrow(props: any) {
  return (
    <BackContainer {...props}>
      <LeftArrow />
    </BackContainer>
  );
}

const BackContainer = styled.div`
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    opacity: 0.6;
  }
`;
