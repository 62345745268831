import React, { useReducer, useEffect } from 'react';
import { hooks } from '@engelvoelkersamericas/common-story-ui';
import { fetchHomePage } from '../../requests/index';

const { useGlobalDispatch } = hooks;

type Action =
  | { type: 'fetching' }
  | { type: 'set home page'; payload: IHomePage }
  | { type: 'set home awards'; payload: IHomeAward[] }
  | { type: 'set award highlights'; payload: IAwardHighlight[] }
  | { type: 'set active award'; payload: string }
  | { type: 'set gallery year'; payload: string }
  | { type: 'set categories'; payload: IAwardCategory[] };

type Dispatch = (action: Action) => void;

type IAwardCategory = {
  id: string;
  name: string;
  orderPosition: number;
};

type IAwardPicture = {
  id: string;
  name: string;
  alternativeText: string;
  caption: string;
  url: string;
};

type IGallery = {
  id: string;
  award: string;
  year: number;
  photos: IPhoto[];
};

export type IPhoto = {
  id: string;
  url: string;
};

type IWinner = {
  id: string;
  award: string;
  year: number;
  columnOne: string;
  columnTwo: string;
  columnThree: string;
};

export type IAward = {
  id: string;
  award_category: IAwardCategory;
  picture: IAwardPicture;
  name: string;
  award_galleries: IGallery[];
  award_winners: IWinner[];
  winnerColumnOne: string;
  winnerColumnTwo: string;
  winnerColumnThree: string;
  overview: string;
  order: number;
};

type IHomePage = {
  title: string;
  heroText: string;
  heroImage: IPhoto;
  gallery: IPhoto[];
  adPositionOne: IAwardAd;
  adPositionTwo: IAwardAd;
  adPositionThree: IAwardAd;
};

export type IHomeAward = {
  id: string;
  name: string;
  picture: IPhoto;
  award_category: IAwardCategory;
  link: string;
};

type IAwardHighlight = {
  id: string;
  title: string;
  quote: string;
  awardWinner: string;
  shop: string;
  year: number;
  award: {
    id: number;
    name: string;
    picture: IPhoto;
  };
  picture: IPhoto;
};

export type IAwardAd = {
  Label: string;
  Link: string;
  Image: { url: string };
};

type IInitialFetch = {
  homePage: IHomePage;
  awards: IHomeAward[];
  awardHighlights: IAwardHighlight[];
  awardCategories: IAwardCategory[];
};

type State = {
  imageGallery: string[] | null;
  featuredWinner: string | null;
  awards: IAward[] | null;
  winners: IWinner[] | null;
  homePage: IHomePage | null;
  homeAwards: IHomeAward[] | null;
  awardHighlights: IAwardHighlight[];
  activeAward: string;
  galleryYear: string;
  categories: IAwardCategory[];
  isFetching: boolean;
};

type AwardsProviderProps = {
  children: React.ReactNode;
};
type InitAwardsProviderProps = {
  children: React.ReactNode;
};

const AwardsStateContext = React.createContext<State | undefined>(undefined);
const AwardsDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const initState = {
  imageGallery: [],
  featuredWinner: null,
  awards: [],
  winners: [],
  homePage: null,
  homeAwards: [],
  awardHighlights: [],
  activeAward: '',
  galleryYear: '',
  categories: [],
  isFetching: true,
};

function awardsReducer(state: State, action: Action) {
  switch (action.type) {
    case 'fetching': {
      return { ...initState, isFetching: true };
    }
    case 'set home page': {
      return { ...state, homePage: action.payload };
    }
    case 'set home awards': {
      return { ...state, homeAwards: action.payload };
    }
    case 'set award highlights': {
      return { ...state, awardHighlights: action.payload };
    }
    case 'set active award': {
      return { ...state, activeAward: action.payload };
    }
    case 'set gallery year': {
      return { ...state, galleryYear: action.payload };
    }
    case 'set categories': {
      return { ...state, categories: action.payload };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

function AwardsProvider({ children }: AwardsProviderProps) {
  const [state, dispatch] = useReducer(awardsReducer, initState);
  return (
    <AwardsStateContext.Provider value={state}>
      <AwardsDispatchContext.Provider value={dispatch}>
        <InitAwardsProvider>{children}</InitAwardsProvider>
      </AwardsDispatchContext.Provider>
    </AwardsStateContext.Provider>
  );
}

function InitAwardsProvider({ children }: InitAwardsProviderProps) {
  const globalDispatch = useGlobalDispatch();
  const dispatch = useAwardsDispatch();
  useEffect(() => {
    globalDispatch({ type: 'set global loading', payload: true });
    getHomePage();
    globalDispatch({ type: 'set global loading', payload: false });
  }, []);

  async function getHomePage() {
    const data: IInitialFetch = await fetchHomePage();
    dispatch({ type: 'set home page', payload: data.homePage });
    dispatch({ type: 'set home awards', payload: data.awards });
    dispatch({ type: 'set award highlights', payload: data.awardHighlights });
    dispatch({ type: 'set categories', payload: data.awardCategories });
  }

  return <React.Fragment>{children}</React.Fragment>;
}

function useAwardsState() {
  const context = React.useContext(AwardsStateContext);
  if (context === undefined) {
    throw new Error('useAwardsState must be used within an AwardsProvider');
  }
  return context;
}
function useAwardsDispatch() {
  const context = React.useContext(AwardsDispatchContext);
  if (context === undefined) {
    throw new Error('useAwardsDispatch must be used within an AwardsProvider');
  }
  return context;
}

export { AwardsProvider, useAwardsState, useAwardsDispatch };
