import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAwardsState } from '../context/awardsContext';
import chevron from '../../assets/chevron.svg';
import { GalleryModal } from '../galleries/GalleryModal';
import { IPhoto } from '../context/awardsContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

export function ImageGallery() {
  const awardsState = useAwardsState();
  const gallery = awardsState.homePage?.gallery;
  const [renderGallery, setRenderGallery] = useState<IPhoto[] | undefined>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);

  useEffect(() => {
    if (gallery && gallery.length < 4) {
      setRenderGallery(gallery.concat(gallery));
    } else {
      setRenderGallery(gallery);
    }
  }, [gallery]);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '19px',
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    nextArrow: <img className='arrow arrow-right' src={chevron} />,
    prevArrow: <img className='arrow arrow-left' src={chevron} />,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          centerMode: false,
        },
      },
    ],
  };

  function openModal(index: number) {
    setModalIndex(index);
    setModalOpen(true);
  }

  return (
    <>
      <StyledGallery>
        <div className='image-gallery'>
          {renderGallery && (
            <Slider {...settings}>
              {renderGallery.map((image, i) => (
                <div className='image-container'>
                  <img src={image.url} onClick={() => openModal(i)} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </StyledGallery>
      {modalOpen && (
        <GalleryModal
          photos={gallery}
          setModalOpen={setModalOpen}
          initial={modalIndex}
        />
      )}
    </>
  );
}

const StyledGallery = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;

  .image-gallery {
    width: 1050px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1120px) {
      width: 900px;
    }

    @media screen and (max-width: 1000px) {
      width: 750px;
    }

    @media screen and (max-width: 850px) {
      width: 650px;
    }

    @media screen and (max-width: 770px) {
      width: 100%;
      height: auto;
    }
  }

  img {
    width: 300px;
    cursor: pointer;

    @media screen and (max-width: 1120px) {
      width: 250px;
    }

    @media screen and (max-width: 1000px) {
      width: 200px;
    }

    @media screen and (max-width: 850px) {
      width: 165px;
    }

    @media screen and (max-width: 770px) {
      width: 90%;
      margin-left: 5%;
    }
  }

  .slick-arrow {
    height: 20px;
    width: 20px;
  }

  .slick-prev {
    transform: rotate(180deg) translateY(10px);

    @media screen and (max-width: 1120px) {
      left: -40px;
    }
  }

  .slick-next {
    @media screen and (max-width: 1120px) {
      right: -40px;
    }
  }

  .slick-track {
    display: flex;
    height: 500px;

    @media screen and (max-width: 770px) {
      height: auto;
    }
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;

    img {
      transition: transform 0.5s ease-in-out;
    }
  }

  .slick-center {
    img {
      transform: scale(1.6);
      transition: transform 0.5s ease-in-out;

      @media screen and (max-width: 770px) {
        transform: scale(1);
      }
    }
  }
`;
