import React from 'react';
import { BrowserRouter as ReactRouter, Switch, Route } from 'react-router-dom';
import { AwardsHome } from './components/home/AwardsHome';
import { AwardOverview } from './components/overview/AwardOverview';
import { AwardWinners } from './components/winners/AwardWinners';
import { AwardGallery } from './components/galleries/AwardGallery';

export default function Router() {
  return (
    <ReactRouter>
      <Switch>
        <Route path='/overview/:id' component={AwardOverview} />
        <Route path='/winners/:id/galleries' component={AwardGallery} />
        <Route path='/winners/:id' component={AwardWinners} />
        <Route path='/'>
          <AwardsHome />
        </Route>
      </Switch>
    </ReactRouter>
  );
}
